// выпадающее меню при клике на "Знания"
const menuKnowledge = document.getElementById('menu-knowledge');
// заголовок "Знания"
const titleKnowledge = document.getElementById('title-knowledge');
// заголовок "Люди"
const titlePeople = document.getElementById('title-people');
// контент страниц
const mainContent = document.querySelector('main');

const menuElements = menuKnowledge.querySelectorAll('li');

for (let element of menuElements) {
    element.addEventListener('click', function(event) {
      event.stopPropagation()
    });
  }

// обработчик клика на меню Знаний
function handleDocumentClick(event) {
  if (titleKnowledge.contains(event.target)) {
    // переключение состояния меню Знаний
    toggleKnowledgeMenu(); 
  } else {
    // закрытие меню Знаний
    closeKnowledgeMenu(); 
  }
}

// Закрытие меню Знаний
function closeKnowledgeMenu() {
  // удаление .open у меню Знаний
  menuKnowledge.classList.remove('open');
  // удаление .active у "Знания" 
  titleKnowledge.classList.remove('active'); 
  // видимость контента страницы
  mainContent.style.display = 'block'; 
}

// Переключение состояния меню Знаний
function toggleKnowledgeMenu() {
  // переключение .open у меню Знаний
  menuKnowledge.classList.toggle('open'); 
  // переключение .active у "Знания"
  titleKnowledge.classList.toggle('active');
  // видимость контента страницы в зависимости от состояния меню Знаний 
  mainContent.style.display = menuKnowledge.classList.contains('open') ? 'none' : 'block'; 
}

// Обработчик клика для всего документа
document.addEventListener('click', handleDocumentClick); 
