import PhotoSwipe from './photoswipe';
import Lightbox from './photoswipe-lightbox';


window.onload = () => {
  let gallerys = document.querySelectorAll(".pswp-gallery");
  const lightbox = new Lightbox({
    gallery: gallerys,
    children: 'a',
    pswpModule: PhotoSwipe
  });
  lightbox.init();
};