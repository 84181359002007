
let listItems = document.querySelectorAll('.nav-js');
console.log(listItems)
let nav = document.querySelector('.guide-nav');
let isContains = document.contains(nav);

if (nav !== null) {
  for (let headerNumber =0; headerNumber < listItems.length; headerNumber = headerNumber + 1) {
    let elem = listItems[headerNumber]
    let elem2 = document.createElement('a');
    let headerId = 'n' + headerNumber;
    elem.id = headerId;

    let linkHref = '#' + headerId;
    console.log(linkHref)
    elem2.href = linkHref;
    elem2.innerHTML = elem.innerHTML;
    console.log(elem2.innerHTML);
    nav.append(elem2)
  }
} 
// let pp = document.getElementById("p1")
// pp.innerHTML = hh.innerHTML;