const elsARR = document.querySelectorAll(".image-processor");

function controller(element) {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const elementTop = rect.top;
  const elementH = rect.height;
  const imgs = element.querySelectorAll("img");

  const centerY = elementTop + elementH / 2;

  if (centerY > windowHeight / 2) {
    imgs[1].style.opacity = "1";
  } else {
    imgs[1].style.opacity = "0";
  }
}

function setAspectRatio(element) {
  const imgs = element.querySelectorAll("img");
  const firstImage = imgs[0];

  firstImage.addEventListener("load", function() {
    const aspectRatio = firstImage.naturalWidth / firstImage.naturalHeight;
    element.style.aspectRatio = aspectRatio;
  });
}

function handleScroll() {
  elsARR.forEach(el => {
    controller(el);
    setAspectRatio(el);
  });
}

window.addEventListener("scroll", handleScroll);
window.addEventListener("resize", handleScroll);
document.addEventListener("DOMContentLoaded", handleScroll());
