// amount of articles in .grid-list on the main page
// shows in span.grid-list-number

const articlesGroups = document.getElementsByClassName('grid-list');

for (let i = 0; i < articlesGroups.length; i++) {
  const articlesGroup = articlesGroups[i];
  const numberSpan = articlesGroup.querySelector("span.grid-list-number");
  const paragraphCount = articlesGroup.getElementsByTagName("p").length - 1;

  if (numberSpan) {
    numberSpan.innerText = paragraphCount;
  }
}