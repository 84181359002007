import Plyr from "plyr";

const playerSelector = '.plyr-player';
const chaptersWrapperSelector = '.chapters-wrapper';
const playerWrapperSelector = '.plyr-player-box';
const timestampButtonsSelector = '.timestamp';

window.addEventListener("DOMContentLoaded", () => {
  // Init Plyr player for all nodes
  const playerNodes = document.querySelectorAll(playerSelector)
  
  playerNodes.forEach(node => {
    const playerWrapper = node.closest(playerWrapperSelector);
    const chapterWrapper = node.closest(chaptersWrapperSelector);
    const vertical = node.dataset.vertical;

    const player = new Plyr(node, {
      controls: ["play","progress", "current-time", "fullscreen"],
      captions: { active: true, update: true },
      ratio: node.dataset.ratio,
    });
    
    // Enable full control for vertical videos 
    if(vertical){
      player.on('enterfullscreen', (event) => {
        playerWrapper.dataset.minimalControls = false
      });
      
      player.on('exitfullscreen', (event) => {
        playerWrapper.dataset.minimalControls = true
      });
    }

    // Init chapter navigation if is exist
    if(chapterWrapper) initChapters(chapterWrapper, player);
  })
});

function initChapters(wrapper, player){
  const timestampButtons = wrapper.querySelectorAll(timestampButtonsSelector);

  // Chapter navigation
  timestampButtons.forEach(btn => {
    btn.onclick
    btn.onclick = async () => {
      const timestamp = parseInt(btn.dataset.timestamp);
      const playerNode = player.elements.wrapper;
      

      // We need play/pause for unmute video 
      // Because browser cant recognize user interaction
      // from intersection observer and muted sound

      await player.play();
      player.currentTime = timestamp;
      player.pause();
      observer.observe(playerNode);

      playerNode.scrollIntoView({ behavior: 'smooth', block: 'center' });

    };
  });

    // Observer for detect player onscreen
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setTimeout(()=>{
          player.play();

          // fire only once
          observer.disconnect()
        }, 500)
      }
    });
  });
}